import React, { FunctionComponent } from "react";
import { graphql, PageProps, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import Hero from "../components/hero";
import { extractHtml, extractImage } from "../util";
import PageText from "../components/page-text";
import PageTitle from "../components/page-title";
import SessionDuration from "../components/session-duration";

const query = graphql`
    query {
        text: markdownRemark(fileAbsolutePath: {regex: "/huiswerkbegeleiding.md/"}) {
            html
        }
    }
`;

const HuiswerkBegeleiding: FunctionComponent<PageProps> = ({ location }) => {
    const data = useStaticQuery(query);
    const { text } = extractHtml(data);

    return (
        <Layout currentPath={location.pathname}>
            <SEO title="Huiswerkbegeleiding" description="Huiswerk hoort bij school, maar is niet altijd even leuk of makkelijk. Graag helpt Praktijk de Leersprong met het bijhouden en goed maken van het huiswerk van uw kind." />
            <Hero />

            <PageTitle>Huiswerkbegeleiding</PageTitle>
            <SessionDuration duration="45 minuten" />

            <Container>
                <PageText text={text} />
            </Container>
        </Layout>
    );
};

export default HuiswerkBegeleiding;